import React, { useState, useEffect } from "react";
import axiosConfig from "../axiosConfig";
import { useParams, useNavigate } from "react-router-dom";

const EmailVerification = () => {
    const { token } = useParams(); // Retrieve the token from the URL
    const navigate = useNavigate(); // For navigation
    const [msg, setMsg] = useState(null);
    const [err, setErr] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true); // Start loading

        // Function to verify the email using the token
        const verifyEmail = async () => {
            try {
                const response = await axiosConfig.post("/email/verification", { token });
                setMsg(response.data.msg); // Success message from backend
                setErr(null); // Clear any errors
            } catch (error) {
                console.log(error);
                if (error.response?.status === 422) {
                    setErr(error.response.data.error);
                } else {
                    setErr("An unexpected error occurred.");
                }
                setMsg(null); // Clear any success message
            }
        };

        verifyEmail();
        setIsLoading(false); // Stop loading
    }, [token]);

    if (isLoading) {
        return (
            <div className="content-wrapper">
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="text-center">
                                <h3>Loading...</h3>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

    return (
        <div className="content-wrapper">
            <div className="content-header row"></div>
            <div className="content-body">
                <section className="row flexbox-container">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                            <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                <div className="card-header border-0 pb-0">
                                    <div className="card-title text-center">
                                        <img
                                            className="w-25"
                                            src="/app-assets/images/logo/bscl-logo.png"
                                            alt="branding logo"
                                        />
                                    </div>
                                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                        <span>TRP System Account Verification</span>
                                    </h6>
                                </div>
                                <div className="card-content">
                                    <div className="card-body">
                                        {err ? (
                                            <div className="alert alert-danger text-center">{err}</div>
                                        ) : (
                                            <div className="alert alert-success text-center">{msg}</div>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-outline-info btn-lg btn-block"
                                            onClick={() => navigate("/")} // Redirect to login or home
                                        >
                                            <i className="ft-unlock" />Go to Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default EmailVerification;