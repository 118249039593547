import { useState, useEffect } from "react";
import axiosConfig from "../axiosConfig";
import Cookies from "universal-cookie";
import { set } from "date-fns";

const Login = () => {
    const cookies = new Cookies();
    const [uname, setUname] = useState("");
    const [pass, setPass] = useState("");
    const [otp, setOTP] = useState(null);
    const [otpState, setOtpState] = useState(false); // Flag for OTP state
    const [msg, setMsg] = useState(null);
    const [err, setErr] = useState({});
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Loading state

    useEffect(() => {
        // Ask for geolocation permission
        navigator.permissions.query({ name: "geolocation" }).then(function (result) {
            if (result.state === "granted") {
                console.log(result.state);
            } else if (result.state === "denied") {
                console.log(result.state);
                alert("Please enable location from your browser settings to get the full functionality of the website.");
            } else if (result.state === "prompt") {
                console.log(result.state);
                alert("Please grant location access permission for full website functionality. Thank you!");
            }
        });
    }, []);

    const handleForm = (e) => {
        e.preventDefault();

        // Clear previous errors
        setErr({});

        if (otpState && (!otp || otp.trim() === "")) {
            // Show OTP required error
            setErr((prev) => ({ ...prev, otp: ["OTP is required."] }));
            return;
        }

        setIsLoading(true); // Start loading
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);

                const obj = {
                    username: uname,
                    password: pass,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    otp: otp || null, // Include OTP if in OTP state
                
                };

                axiosConfig.post("/auth/sign-in", obj).then(
                    (rsp) => {
                        setIsLoading(false); // Stop loading

                        if (rsp.data.status === 22011) {
                            // Email verification required
                            window.location.href = `/email-verify-send/notice/${rsp.data.data}`;
                        } else if (rsp.data.status === 33044) {
                            // OTP required
                            setOtpState(true); // Show OTP input
                            alert(rsp.data.msg); // Notify the user
                        } else {
                            // Successful login
                            cookies.set("_authToken", rsp.data.data.token);
                            cookies.set("_role", rsp.data.data.role);
                            cookies.set("username", rsp.data.data.username);
                            window.location.href = "/";
                        }
                    },
                    (err) => {
                        setIsLoading(false); // Stop loading
                        if (err.response.status === 422) {
                            console.log(err.response.data);
                            setErr(err.response.data);
                        } else if (err.response.status === 423) {
                            window.location.href = "/account/notice";
                        }
                    }
                );
            },
            function (error) {
                setIsLoading(false); // Stop loading
                if (error.code === 1) {
                    alert("Please grant location access permission for full website functionality. Thank you!");
                } else if (error.code === 2) {
                    alert("Location information is unavailable. Please try again.");
                } else if (error.code === 3) {
                    alert("The location access permission timed out. Please try again.");
                } else if (error.code === 4) {
                    alert("An unknown error occurred. Please try again.");
                }
            },
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
    };


    const handleResendOtp = () => {

        setIsLoading(true); // Start loading
        setOTP(null); // Clear OTP input
        setErr({}); // Clear errors
        setMsg(null); // Clear messages

        navigator.geolocation.getCurrentPosition(
            function (position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);

                const obj = {
                    username: uname,
                    password: pass,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    otp: null, // Include OTP if in OTP state
                
                };

                axiosConfig.post("/auth/sign-in", obj).then(
                    (rsp) => {
                        setIsLoading(false); // Stop loading

                        if (rsp.data.status === 22011) {
                            // Email verification required
                            window.location.href = `/email-verify-send/notice/${rsp.data.data}`;
                        } else if (rsp.data.status === 33044) {
                            // OTP required
                            setOtpState(true); // Show OTP input
                            alert(rsp.data.msg); // Notify the user
                        } else {
                            // Successful login
                            cookies.set("_authToken", rsp.data.data.token);
                            cookies.set("_role", rsp.data.data.role);
                            cookies.set("username", rsp.data.data.username);
                            window.location.href = "/";
                        }
                    },
                    (err) => {
                        setIsLoading(false); // Stop loading
                        if (err.response.status === 422) {
                            console.log(err.response.data);
                            setErr(err.response.data);
                        } else if (err.response.status === 423) {
                            window.location.href = "/account/notice";
                        }
                    }
                );
            },
            function (error) {
                setIsLoading(false); // Stop loading
                if (error.code === 1) {
                    alert("Please grant location access permission for full website functionality. Thank you!");
                } else if (error.code === 2) {
                    alert("Location information is unavailable. Please try again.");
                } else if (error.code === 3) {
                    alert("The location access permission timed out. Please try again.");
                } else if (error.code === 4) {
                    alert("An unknown error occurred. Please try again.");
                }
            },
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
    };

    return (
        <div className="content-wrapper">
            <div className="row justify-content-center">
                <div className="content-body col-md-4">
                    <div className="card border-grey border-lighten-3 m-0">
                        <div className="card-header border-0">
                            <div className="card-title text-center">
                                <div className="p-1">
                                    <img src="/app-assets/images/logo/bscl-logo.png" alt="branding logo" />
                                </div>
                            </div>
                            <h6 className="card-subtitle line-on-side text-muted text-center font-medium-5 pt-2">
                                <span>{otpState ? "Enter OTP" : "Login"}</span>
                            </h6>
                        </div>
                        <div className="card-content">
                            <div className="card-body">

                                <div className="d-flex align-items-center mb-2">
                                    <i className="la la-map-marker"></i>
                                    <span className="ml-2" style={{ color: "red" }}>
                                        Please give location access permission.
                                    </span>
                                </div>

                                {otpState && (
                                    <button
                                        className="btn btn-light"
                                        style={{
                                            border: "1px solid #007bff", // Bootstrap primary blue border
                                            color: "#007bff", // Bootstrap primary blue color
                                            borderRadius: "4px", // Slightly rounded square corners
                                            fontSize: "1.5rem", // Bold and larger arrow
                                            width: "40px", // Fixed width
                                            height: "40px", // Fixed height
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            marginRight: "10px",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                                            transition: "all 0.3s ease-in-out", // Smooth hover effect
                                        }}
                                        onClick={() => {                                                                // return to login page
                                            setErr({}); // Clear errors
                                            setMsg(null); // Clear messages
                                            setOTP(null);
                                            setOtpState(false);
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "#f0f8ff") // Light blue on hover
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "transparent") // Revert to original
                                        }
                                    >
                                        <i className="la la-arrow-left" style={{ fontWeight: "bold" }}></i>
                                    </button>
                                )}

                                <form onSubmit={handleForm} className="form-horizontal form-simple" noValidate>
                                    <h6 className="text-center font-weight-bold text-danger">{err.error}</h6>
                                    <h6 className="text-center font-weight-bold text-danger">{err.latitude ? err.latitude[0] : ""}</h6>

                                    {!otpState && (
                                        <>
                                            <fieldset className="form-group position-relative has-icon-left mb-0">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="user-name"
                                                    onChange={(e) => {
                                                        setUname(e.target.value);
                                                    }}
                                                    value={uname}
                                                    placeholder="Your Username"
                                                    required
                                                />
                                                <div className="form-control-position">
                                                    <i className="la la-user"></i>
                                                </div>
                                                <span className="text-danger">{err.username ? err.username[0] : ""}</span>
                                            </fieldset>
                                            <fieldset className="form-group position-relative has-icon-left">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="user-password"
                                                    onChange={(e) => {
                                                        setPass(e.target.value);
                                                    }}
                                                    value={pass}
                                                    placeholder="Enter Password"
                                                    required
                                                />
                                                <div className="form-control-position">
                                                    <i className="la la-key"></i>
                                                </div>
                                                <span className="text-danger">{err.password ? err.password[0] : ""}</span>
                                            </fieldset>
                                        </>
                                    )}

                                    {otpState && (
                                        <fieldset className="form-group position-relative has-icon-left">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="otp"
                                                onChange={(e) => setOTP(e.target.value)}
                                                value={otp}
                                                placeholder="Enter OTP"
                                                required
                                            />
                                            <div className="form-control-position">
                                                <i className="la la-key"></i>
                                            </div>
                                            <span className="text-danger">{err.otp ? err.otp[0] : ""}</span>
                                        </fieldset>
                                    )}

                                    {/* {otpState && (
                                        <button
                                            type="button"
                                            className="btn btn-light btn-sm mb-2"
                                            style={{
                                                border: "1px solid #007bff", // Bootstrap secondary border
                                                color: "#007bff", // Bootstrap secondary text color
                                                fontSize: "0.875rem", // Small font size
                                                borderRadius: "4px", // Slightly rounded square corners
                                                padding: "4px 12px", // Compact padding
                                                cursor: "pointer",
                                            }}
                                            onClick={handleResendOtp} // Use the new handler
                                        >
                                            Resend OTP
                                        </button>
                                    )} */}

                                    <button type="submit" className="btn btn-info btn-block" disabled={isLoading}>
                                        {isLoading ? <i className="la la-spinner la-spin"></i> : <i className="ft-unlock"></i>}{" "}
                                        {otpState ? "Submit OTP" : "Login"}
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div>
                                <p className="float-xl-left text-center m-0">
                                    <a href="/forget-Pass/email" className="card-link">Recover password</a>
                                </p>
                                <p className="float-xl-right text-center m-0">
                                    New to BSCL User? <a href="/" className="card-link">Suggestion</a>
                                </p>
                                <p className="float-xl-right text-center m-0">
                                    Deployer <a href="/deployer/validation" className="card-link">Registration Form</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;