import { React, useState, useEffect, useCallback } from 'react';
import axiosConfig from '../axiosConfig';
import Header from '../Header/Header';
import MainMenu from '../MainMenu/MainMenu';
import Select from 'react-select';
import axios from 'axios';  // Import axios for cancel tokens









// Utility function to calculate the time ago
const timeAgo = (timestamp) => {
    const now = new Date();
    const past = new Date(timestamp);
    const seconds = Math.floor((now - past) / 1000);

    if (seconds < 0) return "Just now"; // Handle future timestamps

    if (seconds < 60) return `${seconds} seconds ago`;
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) return `${interval} years ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} months ago`;
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} days ago`;
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} hours ago`;
    interval = Math.floor(seconds / 60);
    return `${interval} minutes ago`;
};









const DeviceDeploymentCheck = () => {
    const [queryRawData, setQueryRawData] = useState("");
    const [queryViewLogs, setQueryViewLogs] = useState("");
    const [deviceBoxID, setDeviceBoxID] = useState(null);

    const [allBoxes, setAllBoxes] = useState([]);
    const [loadingAvailableBoxes, setLoadingAvailableBoxes] = useState(false);

    const [deviceBoxInfo, setDeviceBoxInfo] = useState(null);
    const [loadingDeviceBoxInfo, setLoadingDeviceBoxInfo] = useState(false);
    const [errorMsgBoxInfo, setErrorMsgBoxInfo] = useState("Please select a valid Box ID");

    const [deviceRawData, setDeviceRawData] = useState(null);
    const [loadingDeviceRawData, setLoadingDeviceRawData] = useState(false);
    const [errorMsgDeviceRawData, setErrorMsgDeviceRawData] = useState("Please select a valid Box ID");

    const [deviceViewLogs, setDeviceViewLogs] = useState(null);
    const [loadingDeviceViewLogs, setLoadingDeviceViewLogs] = useState(false);
    const [errorMsgDeviceViewLogs, setErrorMsgDeviceViewLogs] = useState("Please select a valid Box ID and connect it to a Household if not already connected.");

    const [msgDeviceConnect, setMsgDeviceConnect] = useState(null);
    const [errorMsgDeviceConnect, setErrorMsgDeviceConnect] = useState(null);

    // Fetch available boxes on component mount
    useEffect(() => {
        setLoadingAvailableBoxes(false);
        axiosConfig.get("/device-check/available-boxes").then((rsp) => {
            setAllBoxes(rsp.data);
            setLoadingAvailableBoxes(true);
        }).catch((err) => {
            if (err.response.status === 422) {
                // handle error
            }
        });
    }, []);

    // useCallback for fetchDeviceBoxInfo to ensure it's stable and cancel old requests
    const fetchDeviceBoxInfo = useCallback(() => {
        if (deviceBoxID) {
            const source = axios.CancelToken.source();  // Create cancel token
            axiosConfig.get("/deviceboxinfo/" + deviceBoxID, { cancelToken: source.token }).then((rsp) => {
                setDeviceBoxInfo(rsp.data.device_box_info);
                setLoadingDeviceBoxInfo(true);
                setErrorMsgBoxInfo(null);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log("Request canceled for Box Info:", deviceBoxID);
                } else {
                    if (err.response.status === 414) {
                        setErrorMsgBoxInfo(err.response.data.error.device_box_id);
                    } else if (err.response.status === 415) {
                        setErrorMsgBoxInfo(err.response.data.error);
                    } else {
                        setErrorMsgBoxInfo("Invalid operation, Please try again");
                    }
                }
                setLoadingDeviceBoxInfo(true);

            });

        }
    }, [deviceBoxID]);

    // useCallback for fetchDeviceRawData with request cancellation
    const fetchDeviceRawData = useCallback(() => {
        if (deviceBoxID) {
            const source = axios.CancelToken.source();  // Create cancel token
            axiosConfig.get("/devicerawdata/" + deviceBoxID, { cancelToken: source.token }).then((rsp) => {
                setDeviceRawData(rsp.data.device_raw_data);
                setLoadingDeviceRawData(true);
                setErrorMsgDeviceRawData(null);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log("Request canceled for Box Info:", deviceBoxID);
                } else {
                    if (err.response.status === 414) {
                        setErrorMsgDeviceRawData(err.response.data.error.device_box_id);
                    } else if (err.response.status === 415) {
                        setErrorMsgDeviceRawData(err.response.data.error);
                    } else {
                        setErrorMsgDeviceRawData("Invalid operation, Please try again");
                    }
                }
                setLoadingDeviceRawData(true);

            });
        }
    }, [deviceBoxID]);
    // useCallback for fetchDeviceViewLogs with request cancellation
    const fetchDeviceViewLogs = useCallback(() => {
        if (deviceBoxID) {
            const source = axios.CancelToken.source();  // Create cancel token
            axiosConfig.get("/deviceviewlogs/" + deviceBoxID, { cancelToken: source.token }).then((rsp) => {
                setDeviceViewLogs(rsp.data.device_view_logs);
                setLoadingDeviceViewLogs(true);
                setErrorMsgDeviceViewLogs(null);
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    console.log("Request canceled for Box Info:", deviceBoxID);
                } else {
                    if (err.response.status === 414) {
                        setErrorMsgDeviceViewLogs(err.response.data.error.device_box_id);
                    } else if (err.response.status === 415) {
                        setErrorMsgDeviceViewLogs(err.response.data.error);
                    } else {
                        setErrorMsgDeviceViewLogs("Invalid operation, Please try again");
                    }
                }
                setLoadingDeviceViewLogs(true);

            });
        }
    }, [deviceBoxID]);


    // useEffect to handle Box ID change (clears previous data immediately)
    useEffect(() => {
        setMsgDeviceConnect(null);
        setErrorMsgDeviceConnect(null);
        if (deviceBoxID) {
            // Clear all previous data when a new deviceBoxID is selected
            setDeviceBoxInfo(null);
            setDeviceRawData(null);
            setDeviceViewLogs(null);

            setLoadingDeviceBoxInfo(false);
            setErrorMsgBoxInfo(null);
            fetchDeviceBoxInfo(); // Fetch immediately

            setLoadingDeviceRawData(false);
            setErrorMsgDeviceRawData(null);
            fetchDeviceRawData(); // Fetch immediately

            setLoadingDeviceViewLogs(false);
            setErrorMsgDeviceViewLogs(null);
            fetchDeviceViewLogs(); // Fetch immediately
        }
    }, [deviceBoxID]);


    // Handle Global Refresh (refresh all data)
    const handleGlobalRefresh = () => {
        // Clear all previous data when a new deviceBoxID is selected
        setDeviceBoxInfo(null);
        setDeviceRawData(null);
        setDeviceViewLogs(null);

        setLoadingDeviceBoxInfo(false);
        setErrorMsgBoxInfo(null);
        fetchDeviceBoxInfo(); // Fetch immediately

        setLoadingDeviceRawData(false);
        setErrorMsgDeviceRawData(null);
        fetchDeviceRawData(); // Fetch immediately

        setLoadingDeviceViewLogs(false);
        setErrorMsgDeviceViewLogs(null);
        fetchDeviceViewLogs(); // Fetch immediately
    };

    // Handle Individual Refresh for Device Box Info
    const handleRefreshDeviceBoxInfo = () => {
        setLoadingDeviceBoxInfo(false);
        setDeviceBoxInfo(null);
        setErrorMsgBoxInfo(null);
        fetchDeviceBoxInfo(); // Fetch immediately
    };

    // Handle Individual Refresh for Device Raw Data
    const handleRefreshDeviceRawData = () => {
        setLoadingDeviceRawData(false);
        setDeviceRawData(null);
        setErrorMsgDeviceRawData(null);
        fetchDeviceRawData(); // Fetch immediately
    };

    // Handle Individual Refresh for Device View Logs
    const handleRefreshDeviceViewLogs = () => {
        setLoadingDeviceViewLogs(false);
        setDeviceViewLogs(null);
        setErrorMsgDeviceViewLogs(null);
        fetchDeviceViewLogs(); // Fetch immediately();
    };


    // Connect Device Box with testing household
    // const householdConnect = () => {
    //     setMsgDeviceConnect(null);
    //     setErrorMsgDeviceConnect(null);
    //     axiosConfig.post("/deviceboxconnect", {
    //         device_box_id: deviceBoxID
    //     }).then((rsp) => {
    //         console.log(rsp.data);
    //         setMsgDeviceConnect(rsp.data.message);
    //         setErrorMsgDeviceConnect(null);
    //         //Update all the value of this page
    //         // Clear all previous data when a new deviceBoxID is selected
    //         setDeviceBoxInfo(null);
    //         setDeviceRawData(null);
    //         setDeviceViewLogs(null);

    //         setLoadingDeviceBoxInfo(false);
    //         setErrorMsgBoxInfo(null);
    //         fetchDeviceBoxInfo(); // Fetch immediately

    //         setLoadingDeviceRawData(false);
    //         setErrorMsgDeviceRawData(null);
    //         fetchDeviceRawData(); // Fetch immediately

    //         setLoadingDeviceViewLogs(false);
    //         setErrorMsgDeviceViewLogs(null);
    //         fetchDeviceViewLogs(); // Fetch immediately
    //     }, (err) => {
    //         setMsgDeviceConnect(null);
    //         if (err.response.status === 414) {
    //             setErrorMsgDeviceConnect(err.response.data.error.device_box_id);
    //         } else if (err.response.status === 415) {
    //             setErrorMsgDeviceConnect(err.response.data.error);
    //         } else {
    //             setErrorMsgDeviceConnect("Invalid operation, Please try again");
    //         }
    //         console.log(err.response);

    //     });

    // };


    const SearchRawData = (data) => {
        return data.filter(
            (item) =>
                item?.channel_name?.toLowerCase().includes(queryRawData.toLowerCase())
        );
    };

    const SearchViewLogs = (data) => {
        return data.filter(
            (item) =>
                item?.channel_name?.toLowerCase().includes(queryViewLogs.toLowerCase())
        );
    };

    // Update Device Box info last request time real time count
    const [lastRequest, setLastRequest] = useState(deviceBoxInfo ? deviceBoxInfo.last_request : null);
    const [lastRequestTimeAgo, setLastRequestTimeAgo] = useState('');

    useEffect(() => {
        const updateLastRequest = () => {
            if (lastRequest) {
                setLastRequestTimeAgo(timeAgo(lastRequest));
            }
        };

        // Update time ago immediately on mount
        updateLastRequest();

        const interval = setInterval(() => {
            updateLastRequest(); // Update every minute
        }, 1000); // 60 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [lastRequest]);

    // Update lastRequest when deviceBoxInfo changes
    useEffect(() => {
        if (deviceBoxInfo) {
            setLastRequest(deviceBoxInfo.last_request);
        }
    }, [deviceBoxInfo]);
    //End of Update Device Box info last request time real time count


    return (
        <div><Header title="Device Health" />
            <MainMenu menu="devicehealth" />
            <div class="app-content content" style={{ backgroundColor: "azure", minHeight: "36em" }} >
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                    </div>
                    <div class="content-body">



                        {/* Loading state */}
                        {loadingAvailableBoxes ? (


                            <><div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-content collapse show">
                                            <div className="card-body card-dashboard">
                                                <div className="table-responsive" style={{ border: "none" }}>
                                                    <div className="row align-items-center" style={{ margin: "0" }}>
                                                        {/* Device Box ID Section */}
                                                        <div className="col-12 col-md-8">
                                                            <div className="d-flex align-items-center mb-2" style={{ position: 'relative' }}>
                                                                <label
                                                                    htmlFor="device"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "18px"
                                                                    }}
                                                                >
                                                                    Device Box ID:
                                                                </label>
                                                                <div class="col-md-3">
                                                                    <Select
                                                                        placeholder="Select Box ID"
                                                                        options={allBoxes.map(box => ({ label: box.id, value: box.id }))}
                                                                        onChange={opt => setDeviceBoxID(opt.value)}
                                                                        value={allBoxes.find(box => box.id === deviceBoxID) ? { label: deviceBoxID, value: deviceBoxID } : null} // Update value handling
                                                                        menuPortalTarget={document.body} // Render the menu in a portal
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the Select component has a higher z-index
                                                                            }),
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the menu has a higher z-index
                                                                            }),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Button Section */}
                                                        <div className="col-12 col-md-4 text-md-end">
                                                            <button
                                                                className="btn btn-primary"
                                                                disabled={((!deviceBoxID) || (deviceBoxInfo?.device_id))}  // Disable if deviceBoxID is null or falsy
                                                                
                                                            >
                                                                Connect to Test Household
                                                            </button>
                                                            <span className='text-success'>{msgDeviceConnect}</span>
                                                            <span className='text-danger'>{errorMsgDeviceConnect}</span>
                                                        </div>

                                                        {/* Refresh Button Section */}
                                                        <div className="row mt-3">
                                                            <div className="col-12 text-center">
                                                                <button
                                                                    className="btn btn-success d-flex align-items-center justify-content-center"
                                                                    style={{ width: "150px" }}
                                                                    onClick={handleGlobalRefresh} // You can replace this with your refresh logic if necessary
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24" // Increased size to 24
                                                                        height="24" // Increased size to 24
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-clockwise"
                                                                        viewBox="0 0 16 16"
                                                                        style={{ marginRight: "10px", fontWeight: "bold" }} // Adjusted style for bold appearance
                                                                    >
                                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                    </svg>
                                                                    Refresh
                                                                </button>
                                                            </div>
                                                        </div>


                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                                {/*Loading & Conditionally render based on errorMsgBoxInfo */}
                                {errorMsgBoxInfo === null ? (
                                    loadingDeviceBoxInfo ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                                    {/* Device Box Info Title */}
                                                                    <h4 className="card-title mx-2">Device Box Info</h4>

                                                                    {/* Refresh Button */}
                                                                    <button
                                                                        className="btn btn-success btn-sm d-flex align-items-center mb-2"
                                                                        onClick={handleRefreshDeviceBoxInfo}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-clockwise"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-5"></div>

                                                            </div>

                                                            <div class="table-responsive" >
                                                                <table class="table display nowrap table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Box ID</th>
                                                                            <th>Last Active</th>
                                                                            <th>Household</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>{deviceBoxInfo.id}</td>
                                                                            <td>
                                                                                {deviceBoxInfo.last_request ? (
                                                                                    lastRequestTimeAgo                        //lastRequestTimeAgo + " ( " + deviceBoxInfo.active_duration + " ) "
                                                                                ) : (
                                                                                    <span className="text-danger">ডিভাইস কখনো ডাটা পাঠায়নি</span>
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {deviceBoxInfo.device_id ? (
                                                                                    <> <span className="text-success">হাউজ-হোল্ডে সংযুক্ত হয়েছে </span> {"\u2705"}</>
                                                                                ) : (
                                                                                    <span className="text-danger">হাউজ-হোল্ড সংযুক্ত নয়</span>
                                                                                )}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <div class="card-title">Device Box Info</div>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                {/* Device Box Info Title */}
                                                <h4 className="card-title mx-2">Device Box Info</h4>

                                                {/* Refresh Button */}
                                                <button
                                                    className="btn btn-success btn-sm d-flex align-items-center mb-2"
                                                    onClick={handleRefreshDeviceBoxInfo}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-clockwise"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <h4><span class="danger">{errorMsgBoxInfo}</span></h4>
                                        </div>
                                    </div>
                                )}





                                {errorMsgDeviceRawData === null ? (
                                    loadingDeviceRawData ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                                    {/* Device Box Info Title */}
                                                                    <h4 className="card-title mx-2">Device Raw Data</h4>

                                                                    {/* Refresh Button */}
                                                                    <button
                                                                        className="btn btn-success btn-sm d-flex align-items-center mb-2"
                                                                        onClick={handleRefreshDeviceRawData}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-clockwise"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search With Channel" onChange={e => setQueryRawData(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                <table className="table display nowrap table-striped table-bordered">
                                                                    <thead className="thead-dark" style={{ position: 'sticky', top: 0, backgroundColor: '#343a40', zIndex: 1020 }}>
                                                                        <tr>
                                                                            <th>Channel</th>
                                                                            <th>Server Receive Time</th>
                                                                            <th>Device ID</th>
                                                                            <th>Start Time</th>
                                                                            <th>End Time</th>
                                                                            <th>People</th>
                                                                            <th>Data Sequence</th>
                                                                            <th>Track ID</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SearchRawData(deviceRawData).map((rawData) => (
                                                                            <tr key={rawData.id}>
                                                                                <td>
                                                                                    {rawData.channel_id === '999' ? (
                                                                                        <div>No Content</div>
                                                                                    ) : (
                                                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                alt=""
                                                                                                style={{ maxWidth: '3rem' }}
                                                                                                src={`../../channels/logos/${rawData.logo}`}
                                                                                            />
                                                                                            {rawData.channel_name}
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                                <td>{rawData.server_time}</td>
                                                                                <td>{rawData.device_id}</td>
                                                                                <td>{rawData.start}</td>
                                                                                <td>{rawData.finish}</td>
                                                                                <td>{rawData.people}</td>
                                                                                <td>{rawData.offline}</td>
                                                                                <td>{rawData.id}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <div class="card-title">Device Raw Data</div>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                {/* Device Box Info Title */}
                                                <h4 className="card-title mx-2">Device Raw Data</h4>

                                                {/* Refresh Button */}
                                                <button
                                                    className="btn btn-success btn-sm d-flex align-items-center mb-2"
                                                    onClick={handleRefreshDeviceRawData}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-clockwise"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <h4><span class="danger">{errorMsgDeviceRawData}</span></h4>
                                        </div>
                                    </div>
                                )}




                                {errorMsgDeviceViewLogs === null ? (
                                    loadingDeviceViewLogs ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                                    {/* Device Box Info Title */}
                                                                    <h4 className="card-title mx-2">Device View Logs</h4>

                                                                    {/* Refresh Button */}
                                                                    <button
                                                                        className="btn btn-success btn-sm d-flex align-items-center mb-2"
                                                                        onClick={handleRefreshDeviceViewLogs}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-clockwise"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search With Channel" onChange={e => setQueryViewLogs(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                <table className="table display nowrap table-striped table-bordered">
                                                                    <thead className="thead-dark" style={{ position: 'sticky', top: 0, backgroundColor: '#343a40', zIndex: 1020 }}>
                                                                        <tr>
                                                                            <th>Channel</th>
                                                                            <th>Start Time</th>
                                                                            <th>Duration</th>
                                                                            <th>User (Button)</th>
                                                                            <th>Device Box ID</th>
                                                                            <th>Household</th>
                                                                            <th>Track ID</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SearchViewLogs(deviceViewLogs).map((viewLogs) => (
                                                                            <tr key={viewLogs.id}>
                                                                                <td>
                                                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                                                        <img className="img-fluid" alt="" style={{ maxWidth: "3rem" }} src={`../../channels/logos/${viewLogs.logo}`} />
                                                                                        {viewLogs.channel_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{viewLogs.started_watching_at}</td>
                                                                                <td>{viewLogs.duration}</td>
                                                                                <td>{viewLogs.user_index + 1}</td>
                                                                                <td>{viewLogs.box_id}</td>
                                                                                <td>{viewLogs.household_name}</td>
                                                                                <td>{viewLogs.id}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <div class="card-title">Device View Logs</div>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <div className="col-md-7 d-flex align-items-center text-nowrap">
                                                {/* Device Box Info Title */}
                                                <h4 className="card-title mx-2">Device View Logs</h4>

                                                {/* Refresh Button */}
                                                <button
                                                    className="btn btn-success btn-sm d-flex align-items-center mb-2"
                                                    onClick={handleRefreshDeviceViewLogs}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-clockwise"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <h4><span class="danger">{errorMsgDeviceViewLogs}</span></h4>
                                        </div>
                                    </div>
                                )}
                            </>




                        ) : (
                            <div className="card">
                                <div className="card-header">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                </div>
                            </div>
                        )}






                        <br />
                        <br />


                    </div>
                </div>

            </div>
        </div>


    )
}
export default DeviceDeploymentCheck;