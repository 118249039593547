import React, { useState, useEffect } from "react";
import axiosConfig from "../axiosConfig";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

const EmailSend = () => {
    const { email } = useParams(); // Retrieve the encrypted email from the URL
    const [msg, setMsg] = useState("Your Account is not verified yet. Please check your email for verification.");
    const [err, setErr] = useState(null);
    const [decryptedEmail, setDecryptedEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (!email || email.trim() === "") {
            setErr("Invalid email");
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        axiosConfig
            .post("/handle/mail/data", { data: decodeURIComponent(email) })
            .then((response) => {
                const emaildata = response.data.data;

                if (!emaildata) {
                    setDecryptedEmail("Invalid email");
                    setErr("Invalid email");
                    setMsg(null);
                } else {
                    setDecryptedEmail(emaildata);
                    setErr(null);
                }
            })
            .catch((err) => {
                if (err.response?.status === 422) {
                    setErr(err.response.data.error);
                } else {
                    setErr("An unexpected error occurred.");
                }
                setDecryptedEmail("Invalid email");
                setMsg(null);
            })
            .finally(() => setIsLoading(false));
    }, [email]);



    console.log(decryptedEmail);
    // // Validate and decrypt the email
    // useEffect(() => {
    //     let decrypted;
    //     try {
    //         const bytes = CryptoJS.AES.decrypt(decodeURIComponent(email), process.env.REACT_APP_SECRET_KEY);
    //         decrypted = bytes.toString(CryptoJS.enc.Utf8).trim(); // Decrypt the email and trim spaces
    //     } catch (error) {
    //         console.error("Decryption failed:", error);
    //         decrypted = "Invalid email";
    //     }

    //     if (!decrypted || decrypted === "" || decrypted === "Invalid email") {
    //         setDecryptedEmail("Invalid email");
    //         setErr("Invalid email");
    //         setMsg(null);
    //     } else {
    //         setDecryptedEmail(decrypted);
    //         setErr(null); // No error
    //     }
    //     setIsLoading(false); // Stop loading once decryption is handled
    // }, [email]);

    // Format the email with 5 asterisks and last 2 characters
    const formatEmail = (email) => {
        const [localPart, domain] = email.split("@");
        if (!localPart || !domain) return "Invalid email format";
        const lastTwo = localPart.slice(-2); // Get the last two characters of the local part
        return `*****${lastTwo}@${domain}`;
    };

    // Handle resend email action
    const handleForm = async () => {
        setIsLoading(true);
        if (!decryptedEmail || decryptedEmail === "Invalid email") {
            setErr("Invalid email");
            setMsg(null);
            return;
        }

        try {
            const response = await axiosConfig.post("/resend-email/verification", { data: decodeURIComponent(email) });
            setMsg(response.data.msg);
            setErr(null);
        } catch (error) {
            console.error("Resend email failed:", error);
            if (error.response?.status === 422) {
                setErr(error.response.data.error);
            } else {
                setErr("An unexpected error occurred.");
            }
            setMsg(null);
        }
        setIsLoading(false);
    };

    // Render loading state
    if (isLoading) {
        return (
            <div className="content-wrapper">
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="text-center">
                                <h3>Loading...</h3>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

    // Render the main UI
    return (
        <div className="content-wrapper">
            <div className="content-header row"></div>
            <div className="content-body">
                <section className="row flexbox-container">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                            <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                <div className="card-header border-0 pb-0">
                                    <div className="card-title text-center">
                                        <img className="w-25" src="/app-assets/images/logo/bscl-logo.png" alt="branding logo" />
                                    </div>
                                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                        <span>TRP System Account Verification</span>
                                    </h6>
                                </div>
                                <div className="card-content">
                                    <div className="card-body">
                                        {err ? (
                                            <div className="alert alert-danger text-center">{err}</div>
                                        ) : (
                                            <div className="text-center">
                                                <h3>{msg}</h3>
                                                <p className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                                    <span>{formatEmail(decryptedEmail)}</span>
                                                </p>
                                            </div>
                                        )}
                                        {decryptedEmail && decryptedEmail !== "Invalid email" && (
                                            <button
                                                type="button"
                                                className="btn btn-outline-info btn-lg btn-block"
                                                onClick={handleForm}
                                            >
                                                <i className="ft-unlock" /> Resend Email
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="card-footer border-0">
                                    <p className="float-sm-left text-center">
                                        Go to <a href="/" className="card-link">
                                            Login
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default EmailSend;